import React, { useEffect, useState } from 'react';
import { Checkbox, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { weight } from 'postcss-minify-font-values/types/lib/keywords';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
  ProviderOffersI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { tableCellStyle } from 'src/e2e-redesign/views/LenderResponses/new-lender-response-table';
import { ApplicationSubmissionStateDto, AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';

interface ResponseCardProps {
  card_details: ProviderOffersI;
  selectedOffer: string;
  onSelect: (fpUniqueId: string) => void;
  isPending?: boolean;
  displayFields: {
    account_number: boolean;
    offer_name: boolean;
    approved_amount: boolean;
    rate: boolean;
    apr: boolean;
    term: boolean;
    monthly_payment: boolean;
    application_id: boolean;
    deferred_interest_period: boolean;
    minimum_spend: boolean;
    promotion_description: boolean;
  };
}

export default function NewLenderResponseRow(props: ResponseCardProps) {
  const { card_details, selectedOffer, onSelect, displayFields, isPending } = props;
  const submissionDetails: ApplicationSubmissionStateDto = card_details.sub_details;
  const [offerLogicObj, setOfferLogicObj] = useState<ProviderOfferLogicObjectI>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (card_details) {
      const obj = e2eConfigLogic_buildProviderResponseLogicObject(card_details);
      if (obj) {
        setOfferLogicObj(obj);
      }
    }
  }, [card_details]);

  if (!offerLogicObj) return null;

  const updateWording = (word: string) => {
    if (word === AppSubLenderResponseE.rejected) {
      return 'Declined';
    }
    if (word === AppSubLenderResponseE.nr) {
      return 'Pending';
    }
    return word;
  };

  const formatCurrency = (value: number | undefined) => {
    return value ? `$${value.toFixed(2)}` : 'N/A';
  };

  const renderMobileRow = (label: string, value: React.ReactNode) => {
    let styleLabel = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'initial',
      fontSize: '1rem',
    };
    const styleValue = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
    };
    if (label.includes('Offer') && label !== 'Offer Name') {
      styleLabel = {
        ...styleLabel,
        fontWeight: 'bold',
        fontSize: '1.2rem',
      };
    }

    return (
      <TableRow>
        <TableCell sx={styleLabel} component="th" scope="row">
          {label.toUpperCase()}
        </TableCell>
        <TableCell sx={styleValue} align="right">
          {value}
        </TableCell>
      </TableRow>
    );
  };

  return (
    submissionDetails.lender_offers &&
    submissionDetails.lender_offers.map((lenderOffer, index) => {
      const isSelected = selectedOffer === lenderOffer?.fp_unique_id;

      if (isMobile) {
        return (
          <React.Fragment key={lenderOffer.fp_unique_id}>
            {!isPending &&
              renderMobileRow(
                `Offer ${index + 1}`,
                <Checkbox
                  checked={isSelected}
                  onChange={() => onSelect(lenderOffer.fp_unique_id as string)}
                  inputProps={{ 'aria-label': 'select offer' }}
                />,
              )}

            {renderMobileRow('Response', updateWording(submissionDetails.lender_response))}
            {lenderOffer.offer_name && renderMobileRow('Offer Name', lenderOffer?.offer_name || 'N/A')}
            {displayFields.account_number && renderMobileRow('Account Number', lenderOffer?.account_number || 'N/A')}
            {displayFields.approved_amount &&
              renderMobileRow('Approved Amount', formatCurrency(Number(lenderOffer?.approved_amount)))}
            {displayFields.rate && renderMobileRow('Rate', lenderOffer?.rate || 'N/A')}
            {displayFields.apr && renderMobileRow('APR', lenderOffer?.apr ? `${lenderOffer.apr}%` : 'N/A')}
            {displayFields.term && renderMobileRow('Term', lenderOffer?.term || 'N/A')}
            {displayFields.monthly_payment &&
              renderMobileRow('Monthly Payment', formatCurrency(lenderOffer?.monthly_payment))}
            {displayFields.application_id && renderMobileRow('Application ID', lenderOffer?.application_id || 'N/A')}
            {displayFields.deferred_interest_period &&
              renderMobileRow('Deferred Interest Period', lenderOffer?.deferred_interest_period || 'N/A')}
            {displayFields.minimum_spend && renderMobileRow('Minimum Spend', lenderOffer?.minimum_spend || 'N/A')}
            {displayFields.promotion_description &&
              renderMobileRow('Promotion Description', lenderOffer?.promotion_description || 'N/A')}
          </React.Fragment>
        );
      }

      return (
        <TableRow key={lenderOffer.fp_unique_id}>
          {!isPending && (
            <TableCell align="center" style={tableCellStyle}>
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(lenderOffer.fp_unique_id as string)}
                inputProps={{ 'aria-label': 'select offer' }}
              />
            </TableCell>
          )}

          {displayFields.account_number && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.account_number || 'N/A'}
            </TableCell>
          )}
          <TableCell align="center" style={tableCellStyle}>
            {updateWording(submissionDetails.lender_response)}
          </TableCell>
          {displayFields.approved_amount && (
            <TableCell align="center" style={tableCellStyle}>
              {formatCurrency(Number(lenderOffer?.approved_amount))}
            </TableCell>
          )}
          {displayFields.offer_name && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.offer_name || 'N/A'}
            </TableCell>
          )}
          {displayFields.rate && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.rate || 'N/A'}
            </TableCell>
          )}
          {displayFields.apr && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.apr ? `${lenderOffer.apr}%` : 'N/A'}
            </TableCell>
          )}
          {displayFields.term && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.term || 'N/A'}
            </TableCell>
          )}
          {displayFields.monthly_payment && (
            <TableCell align="center" style={tableCellStyle}>
              {formatCurrency(lenderOffer?.monthly_payment)}
            </TableCell>
          )}
          {displayFields.application_id && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.application_id || 'N/A'}
            </TableCell>
          )}
          {displayFields.deferred_interest_period && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.deferred_interest_period || 'N/A'}
            </TableCell>
          )}
          {displayFields.minimum_spend && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.minimum_spend || 'N/A'}
            </TableCell>
          )}
          {displayFields.promotion_description && (
            <TableCell align="center" style={tableCellStyle}>
              {lenderOffer?.promotion_description || 'N/A'}
            </TableCell>
          )}
        </TableRow>
      );
    })
  );
}
