export enum AppSubStatusE {
  'start' = 'Start', // Nothing has happened
  'redirected' = 'Redirected', // application has been redirected = 'r'
  'job_created' = 'Job Created', // job has been created and put in the queue
  'submitted' = 'Submitted', // application has been submitted
  'processing' = 'Processing', // application is being processed
  'error' = 'Error', // there was an error
  'lender_responded' = 'Lender Responded', // lender provided a response
  'completed' = 'Completed', // applicant accepted/rejected the deal
  'regrading' = 'Regrading', // Regrading application
}

export enum AppSubLenderResponseE {
  'invalid' = 'Invalid',
  'nr' = 'No Response',
  'approved' = 'Approved',
  'rejected' = 'Rejected',
  'hold' = 'Hold',
  'pending' = 'Pending',
  'pre_approved' = 'Pre Approved',
  'soft_decline' = 'Soft Decline',
  'regrade_error' = 'regrade_error',
  'timed_out' = 'timed_out',
  'contract_sent' = 'contract_sent',
  // E2E Specific Responses
  'e2e_pre_approved' = 'Pre-Qualified',
}

export enum ErrorTypeEnums {
  'init' = 'init', // Failed to clear for API pipe
  'system' = 'system',
  'store' = 'store',
  'lender' = 'lender',
  'applicant' = 'applicant',
}

export enum ApplicationContractStatusE {
  'contract_sent' = 'Contract Sent',
  'contract_canceled' = 'Contract Canceled',
}

export enum ApplicationSubmissionEventTypeEnums {
  'FirstClearedForPipe' = 'FirstClearedForPipe',
  'SecondClearedForPipe' = 'SecondClearedForPipe',
  'InitSubmissionObject' = 'InitSubmissionObject',
  'JobCreated' = 'JobCreated',
  'ApplicationSubmitted' = 'ApplicationSubmitted',
  'ApplicationProcessed' = 'ApplicationProcessed',
  'ApplicationComplete' = 'ApplicationComplete',
  'ApplicationRegrading' = 'ApplicationRegrading',
  'ContractSent' = 'ContractSent',
  'ContractCanceled' = 'ContractCanceled',
}

export enum LenderOfferValueTypesE {
  'percentage' = 'percentage',
  'number' = 'number',
  'string' = 'string',
  'currency' = 'currency',
  'url' = 'url',
  'send_offer_rul' = 'send_offer_rul',
}

export class LenderOffersDetailedI {
  id!: string;
  show!: boolean;
  label!: string;
  value!: string;
  type: LenderOfferValueTypesE = LenderOfferValueTypesE.string;
}

export interface LenderOffersI {
  // offer_id, application_id, pre_qualification_id or token MUST exist for every offer object
  offer_id?: string;
  application_id?: string; // this is provided when user accepts
  pre_qualification_id?: string;
  token?: string;
  lender_product_type?: string; // used for aff

  // fp_unique_id MUST exist for every offer
  fp_unique_id?: string; // TODO E2E P0 - we need to add this to every offer
  redirect_url?: string;
  delivery_date_required?: boolean;
  alternate_message?: string; // !!Using this will show the alternate-message component on merchant-client
  approved_amount?: string | number;
  apr?: number;
  interest_rate?: number;
  monthly_payment?: number;
  offer_name?: string;
  offer_url?: string;
  term?: number;
  in_store_payment?: string;
  invoice_amount?: string;
  frequency?: string;
  lender_code?: string;
  financing_partner_code?: string;
  financing_partner_name?: string;
  financing_partner?: string;
  normal_payment?: string;
  payment_amount?: string;
  number_payments?: string;
  last_payment?: string;
  zip_term?: number;
  has_accepted?: boolean; // noting whether the user accepts or not
  amount_taken?: number; // actual amount financed
  // GAFCO specific
  available_credit?: string | number;
  open_to_buy?: string;
  // Kornerstone specific:
  tax_rate?: number;
  sales_tax_exempt?: boolean;
  lease_multiple?: number;
  initial_payment_amount?: number;
  // Sweetpay specific:
  interest_free_period?: number;
  monthly_payment_during_interest_free_period?: number;
  apr_after_interest_period?: number;
  monthly_payment_after_interest_period?: number;
  lender_name?: string;
  seller_name?: string;
  url?: string;
  applicant_name?: string;
  offer_description?: string;
  location?: string;
  // Koalafi
  application_fee?: string;
  early_buyout_discount?: string;
  sac_term?: number;
  lease_term?: string;
  product_id?: number;
  deferred_interest_period?: number;
  minimum_spend?: string;
  rate?: string;
  // Eve specific - this will send allow the user to send the offer to the applicant
  send_offer_url?: string;
  customer_id?: string;
  is_approved?: boolean;
  down_payment?: number;
  due_at_signing?: number;
  legal?: string;
  /**
   * Creating this new field so we can store complex structures for
   * - renaming, order, formatting, etc.
   */
  fp_detailed?: LenderOffersDetailedI[];
  // UMWSB Specific - object of params to include
  send_contract_url?: object;
  contract_envelope_id?: string; // for UMWSB
  fp_data?: {
    contract_sent?: boolean; // THIS IS FOR FP USE ONLY - it indicates that this offer has been sent
    // customer_notification?: ApplicationSubmissionCustomerNotificationI; // used to send customer information
    contract_signed?: boolean;
  };
  // Synchrony items
  application_key?: string;
  offer_expiry_date?: string;
  token_status?: string;
  application_decision?: string;
  coupon_number?: string;
  product_code?: string;
  account_number?: string;
  temporary_account_number?: string;
  security_code?: string;
  credit_limit?: string;
  temporary_credit_limit?: string;
  card_expiry?: string;
  mmc_code?: string;
  mmc_description?: string;
  annual_purchase_rate?: string;
  daily_rate?: string;
  cash_advance_apr?: string;
  cash_advanced_daily_rate?: string;
  delinquency_apr?: string;
  delinquency_rate?: string;
  tier_code?: string;
  caoptin?: string;
  flow_type?: string;
  integration_channel?: string;
  fico_score?: string;
  buero_code?: string;
  card_art_url?: string;
  origination_fee?: number;
  financing_amount?: number;
  reference_number?: string;
  current_status?: string;
  credit_line?: string;
  available_credit_line?: string;
  lender?: string;
  lender_logo?: string;
  decline_reason?: string;
  payment_request_id?: string;
  first_payment_date?: string;
  message?: string;
  // not added to SQL
  promotion_description?: string;
}

export class ApiPipeErrorsI {
  error_type: ErrorTypeEnums = ErrorTypeEnums.init;
  error_messages: string[] = [];
  error_details: any;
  prevent_api_pipe!: boolean;
  status_code?: number;
  status_message?: string;
}

export class ApplicationSubmissionLogDto {
  event_type: ApplicationSubmissionEventTypeEnums | undefined;
  errors?: ApiPipeErrorsI;
  data: any;
  log_date?: string;
}

export interface PullStatusI {
  enabled?: boolean;
  interval?: number; // in min
  max_time?: number; // in min
  end_run_time?: number;
  user_id?: number;
}

export enum AppSubmissionE2eCurrentStagesE {
  'init' = 'init',
  'proceed' = 'proceed',
  'send_contract' = 'send_contract',
  'cancel_contract' = 'cancel_contract',
  'regrade' = 'regrade',
}

export enum FlowEndPointsE {
  'initApp' = 'initApp',
  'submitPreApproval' = 'submitPreApproval',
  'getAppStatus' = 'getAppStatus',
  'submitContract' = 'submitContract',
  'hardSubmit' = 'hardSubmit',
  'regrade' = 'regrade',
  'redirectUrl' = 'redirectUrl',
  'cancelContract' = 'cancelContract',
  'na' = 'na', // when disabled
}

export enum E2eFlowStageE {
  'init' = 'init', // applicant section
  'proceed' = 'proceed',
  'send_contract' = 'send_contract',
  'cancel_contract' = 'cancel_contract',
  'regrade' = 'regrade',
}

export interface E2eFlowStageI {
  stage: E2eFlowStageE;
  is_enabled: boolean; // checks flow object to see if it exists
  completed: boolean;
  time_completed: string;
  unix_time_completed: number;
  endpoints_completed: FlowEndPointsE[];
  can_retry: boolean; // indicates whether this endpoint can be hit again - comes from whether or not it failed
  can_retry_after_completed: boolean;
  next_stage: E2eFlowStageE;
}

export interface ApplicationSubmissionStateDto {
  do_not_display?: boolean;
  do_not_display_message?: string;
  submission_state: AppSubStatusE;
  lender_response: AppSubLenderResponseE;
  applicant_accepted?: boolean;
  lender_offers?: LenderOffersI[];
  lender_application_id?: string;
  applicant_id?: number;
  lender_order_id?: string;
  lender_reference_id?: string;
  submission_date: string;
  lender_additional_details?: any;
  merchant_form_details?: any;
  lender_id: number;
  errors: ApiPipeErrorsI;
  log: ApplicationSubmissionLogDto[];
  is_submitted: boolean;
  submission_id: string;
  lender_name: string;
  form_id: number;
  store_id: number;
  org_id: number;
  submitted_user_id: number;
  pre_approval_uuid?: string;
  pre_approved_signature_accepted?: boolean;
  message?: string;
  lender_pending_message?: string;
  lender_declined_message?: string;
  include_lender_logo?: boolean;
  lender_phase?: string;
  pull_for_status_details?: PullStatusI;
  full_response?: any[];
  is_e2e?: boolean;
  contract_status?: ApplicationContractStatusE;
  redirected_to_provider?: boolean;
  current_e2e_stage?: AppSubmissionE2eCurrentStagesE;
  current_e2e_endpoint?: FlowEndPointsE;
  lender_approved_message?: string;
  e2e_flow_stages?: E2eFlowStageI[];
}
